<template>
  <div id="form-helper" class="pa-5">
    <div class="row">
      <div class="col-6">
        <div class="card card-custom">
          <div class="card-body">
            <FormHelper
              v-if="1"
              ref="form"
              :key="formHelperKey"
              v-model="values"
              :form="form"
              :config="configNew"
              @action="onAction"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom">
          <div class="card-body">
            <h5>Values</h5>
            <pre>{{ values }}</pre>
            <b-form-checkbox v-model="stackLabels" switch>
              Stack Labels
            </b-form-checkbox>
            <b-form-checkbox v-model="disableFields" switch @change="setDisabled">
              Disable
            </b-form-checkbox>
            <button class="btn btn-primary" @click="validate">Validate</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_CUSTOM_VARIABLES } from "@/core/services/store/variables.module";

import Languages from "@/components/General/Languages/languages";

export default {
  components: {
    FormHelper
  },
  props: {},
  data() {
    return {
      stackLabels: false,
      disableFields: false,
      formHelperKey: 1,
      values: {
        text: {
          test: {
            text1: "1"
          }
        },
        num: 123,
        password: "123",
        select: "",
        multiselect: ["test1", "test2"],
        checkbox: true,
        file: [],
        editor: "",
        json: {},
        condition: {
          "type": "group",
          "operator": "and",
          "children": [
            {
              "type": "condition",
              "field": "{{config.mailconfig}}",
              "operator": "==",
              "value": "test",
              "valid": true
            }
          ],
          "valid": true
        }
      },
      config: {
        title: "Basiselemente",
        labelStacked: false,
        snippetPrefix: "formHelper.test",
        enableVariables: true,
        customVariables: [
          {
            name: "demo",
            prefix: "demo"
          }
        ]
      },
      form: [
        {
          label: "Image Upload",
          type: "image",
          name: "image",
          ratio: 2 / 3
        },
        {
          label: "label",
          name: "text.test.text1",
          type: "text",
          placeholder: "placeholder",
          // prepend: "prepend",
          // prependIcon: "fal fa-face-awesome",
          // append: "append",
          // appendIcon: "fal fa-face-explode",
          help: "yedi",
          copy: true,
          enableVariables: true,
          default: "Test Text",
          validations: {
            required: true,
            minLength: 3,
            alphaNumSpace: true
          },
          dependsOn: [
            {
              name: "select",
              notValues: ["test2", "test3"]
            }
          ]
        },
        {
          label: "labelNum",
          name: "num",
          type: "number",
          placeholder: "placeholderNum",
          validations: {
            required: true,
            integer: true
          },
          dependsOn: [
            {
              name: "multiselect",
              values: ["test1"],
              notValues: ["test3"]
            }
          ]
        },
        {
          type: "password",
          label: "Password",
          name: "password",
          validations: {
            required: true,
            minLength: 8,
            maxLength: 20
          }
        },
        {
          type: "select",
          label: "selectFeld",
          name: "select",
          placeholder: "select",
          clearable: true,
          default: "test1",
          options: [
            {
              group: "Test",
              value: "test1"
            },
            {
              group: "Test",
              value: "test2"
            },
            {
              group: "Test2",
              value: "test3"
            }
          ],
          validations: {
            required: true
          }
        },
        {
          type: "hidden",
          name: "hidden",
          value: true
        },
        {
          type: "multiselect",
          label: "multiSelectFeld",
          name: "multiselect",
          placeholder: "select",
          clearable: true,
          options: ["test1", "test2", "test3"],
          validations: {
            required: true,
            minLength: 2,
            maxLength: 2
          }
        },
        {
          type: "checkbox",
          name: "checkbox",
          label: {
            de: "Geil wa?",
            en: "Cool bro"
          },
          validations: {
            required: true
          }
        },
        {
          type: "file",
          name: "file",
          accept: [".pdf", ".csv"],
          size: 80,
          multiple: true
        },
        {
          type: "alert",
          color: "success",
          style: "notice",
          icon: "fal fa-circle-check",
          label: "label",
          dense: true
        },
        {
          type: "textarea",
          default: "<h1>H1</h1>",
          name: "textarea",
          label: "label"
        },
        {
          type: "texteditor",
          label: "label",
          name: "editor",
          default: "<h1>H1</h1>",
          validations: {
            maxLength: 10
          }
        },
        {
          type: "datetime",
          name: "datetime",
          label: "label"
        },
        {
          type: "range",
          name: "range",
          label: "label",
          // sliderLabel: "range",
          prependIcon: "fal fa-face-zany",
          min: 0,
          max: 100,
          step: 1
        },
        {
          type: "time",
          name: "time",
          label: "label"
        },
        {
          type: "date",
          name: "date",
          label: "label"
        },
        {
          type: "action",
          action: {
            method: "GET",
            url: process.env.VUE_APP_API_ADMIN + "/languages"
          },
          label: "label",
          buttonLabel: "actionLabel"
        },
        {
          type: "json",
          label: "json",
          name: "json",
          default: {
            test1: "Test 1234 Test 5678",
            laengererName: true,
            "wir freuen uns": "wir freuen uns",
            "schauen wir mal was wird": "was wird",
            test5: 123,
            test6: "123",
          },
          fields: [
            {
              name: "name"
            },
            {
              name: "value"
            }
          ]
        },
        {
          label: "label",
          name: "code",
          type: "code",
          lang: "php",
          style: "dark",
          minHeight: 200
        },
        {
          label: "label",
          type: "condition",
          name: "condition"
        }
      ]
    };
  },
  computed: {
    configNew: function () {
      let config = this.config;
      config.labelStacked = this.stackLabels;
      return config;
    }
  },
  watch: {
    stackLabels: function () {
      this.formHelperKey++;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "FormHelper Demo"
      }
    ]);
    this.setDemoVariables();
  },
  methods: {
    validate() {
      let valid = this.$refs.form.validate();
      console.log("Valid: ", valid);
    },
    setDemoVariables() {
      const demoSet = {
        name: "demo",
        variables: [
          {
            text: "demoFlat",
            value: "test123"
          },
          {
            text: "demoNested",
            value: {
              level1: {
                level2: {
                  level3: "test123"
                }
              }
            }
          }
        ]
      };
      this.$store.dispatch("variables/" + SET_CUSTOM_VARIABLES, demoSet);
    },
    async demoAction() {
      await Languages.getAll().then(response => {
        console.log(response);
      });
    },
    onAction(payload) {
      console.log("Field: ", payload.field);
      console.log("Data: ", payload.data.data);
    },
    setDisabled() {
      this.form.forEach((field, index) => {
        this.$set(this.form[index], "disabled", this.disableFields);
      });
    }
  }
};
</script>
